<template>
  <h5 class="col-12 mt-3">Oefenwedstrijden</h5>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Matches extends Vue {

}
</script>

<style scoped>
</style>